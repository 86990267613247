<template>
  <nav>
    <ul class="breadcrumb">
      <li
        v-for="(crumb, index) in crumbs"
        :key="index"
        class="breadcrumb__item"
      >
        <router-link v-if="isLast(index)" :to="crumb.link" class="breadcrumb__link">{{crumb.name}}</router-link>
        <p class="breadcrumb__default-link" v-else>
          {{crumb.name}}
        </p>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    crumbs: {
      type: Array,
      required: true,
    },
  },
  computed: {
    isLast() {
      return (index) => this.crumbs.length !== index + 1
    }
  },
  methods: {
    selected(crumb) {
      this.$emit('selected', crumb);
    },
  },
};
</script>

<style lang="scss" scoped>
.breadcrumb {
  display: flex;
  list-style: none;

  &__item {
    display: flex;
    &:after {
      content: '/';
      padding: 0 .3rem;
    }
  }

  &__link, &__item {
    color: $color-grey-3;
  }

  &__default-link {
    cursor: default;
  }
}
</style>
