
import { defineComponent } from 'vue';
import Breadcrumbs from '@/components/ui/Breadcrumbs.vue';
import AddVehicleModal from '@/components/modals/AddVehicleModal.vue';
import BaseTable from '@/components/ui/BaseTable.vue';
import SearchDropdown from '@/components/search/SearchDropdown.vue';
import vehicleModels from '@/services/vehicleModels';
import { VehicleMutationTypes } from '@/store/modules/vehicles/mutation-types';
import vehicleBrands from '@/services/vehicleBrands';
import { FilterNames } from '@/store/modules/vehicles/state';
import Spinner from '@/components/Spinner.vue';

export default defineComponent({
  name: 'VehiclesTable',
  components: {
    Spinner,
    AddVehicleModal,
    Breadcrumbs,
    BaseTable,
    SearchDropdown
  },
  data: () => ({
    isLoading: true,
  }),
  computed: {
    filterNames() {
      return FilterNames
    },
    headers() {
      return [
        {
          title: 'Марка',
          content: (item: any) => item.model.brand.brand
        },
        {
          title: 'Модель авто',
          content: (item: any) => item.model.model
        },
        {
          title: 'V двигателя',
          content: (item: any) => item.desc.enginesL
        },
        {
          title: 'Тип топлива',
          content: (item: any) => item.desc && item.desc.fuelType && item.desc.fuelType.toLowerCase()
        },
        {
          title: 'Мощность',
          content: (item: any) => item.desc.hp
        },
        {
          title: 'Выпуск',
          content: (item: any) => item.desc.vehYears
        },
        {
          title: 'Тип кузова',
          content: (item: any) => item.desc.bodyType
        },
        {
          title: 'Код двигателя',
          content: (item: any) => item.motor
        },
        {
          title: 'Модификация',
          content: (item: any) => item.desc.modBodyNum
        },
      ]
    },
    crumbs(): any {
      const vehicleTypes = this.$store.getters.getVehicleTypes

      let crumbNameCarsRoute = 'Поиск'
      let crumbNameCarsRouteLink = '/cars'

      const vehicleTypeName: any = vehicleTypes.filter((type: any) => type.key === this.$route.query.vehicleType)
      let vehicleTypeLink: any = `/cars/brands?vehicleType=${this.$route.query.vehicleType}`
      let vehicleModelLink: any = `/cars/brands/${this.$route.query.brandId}/models?vehicleType=${this.$route.query.vehicleType}`

      if (this.$route.query && this.$route.query.vehicleState) {
        switch (this.$route.query.vehicleState) {
          case 'actual':
            crumbNameCarsRoute = 'Актуальные'
            break;
          case 'blocked':
            crumbNameCarsRoute = 'Заблокированные'
            break;
          case 'all':
            crumbNameCarsRoute = 'Все'
            break;
        }

        crumbNameCarsRouteLink = `/cars?vehicleState=${this.$route && this.$route.query.vehicleState}`
        vehicleTypeLink += `&vehicleState=${this.$route.query.vehicleState}`
        vehicleModelLink += `&vehicleState=${this.$route.query.vehicleState}`
      }

      return [
        {
          name: crumbNameCarsRoute,
          link: crumbNameCarsRouteLink
        },
        {
          name: 'Бренды',
          link: vehicleTypeLink
        },
        {
          name: 'Модели',
          link: vehicleModelLink
        },
        {
          name: 'Результат',
          link: null
        }
      ]
    },
    userCanBlockVehicles(): any {
      return this.$store.getters.getAllPermissions.blocked_vehicles[this.$store.getters.getUserRole]
    },
    userCanAddingVehicles(): any {
      return this.$store.getters.getAllPermissions.add_new_vehicles[this.$store.getters.getUserRole]
    },
    filters(): any {
      return (filterName: any) => this.$store.getters.getFilters[filterName]
    },
    vehicles(): any {
      return this.$store.getters.getFilteredItems
    }
  },
  methods: {
    goToPage(item: any) {
      this.$router.push({name: 'vehicle', params: { carId: item.id }})
    },
    update(filterName: string, data: any) {
      this.$store.commit(VehicleMutationTypes.SET_FILTER, [
        {
          filterName,
          item: data,
          conditionFilter: (item: any) => {
            if (!data.length) return true

            if (filterName === FilterNames.FUEL) {
              return item.desc.fuelType.toLowerCase() === data.toLowerCase()
            }

            if (filterName === FilterNames.ENGINE && (item && item.desc && item.desc.enginesL)) {
              return item.desc.enginesL.toLowerCase() === data.toLowerCase()
            }
          }
        },
      ])
    },
    openAddVehicleModal() {
      let element = document.getElementById(`modal-vehicle-add`)!
      element.style.display = 'flex'
    },
  },
  async mounted() {
    const modelId = this.$route.query.modelId as any
    const brandId = this.$route.query.brandId as any

    this.isLoading = true

    this.$store.commit(
      VehicleMutationTypes.SET_VEHICLES,
      await vehicleModels.getModelsByIds(modelId)
    )

    const model = await vehicleModels.getModelById(modelId.split(',')[0])
    const brand = await vehicleBrands.findBrandById(brandId)

    this.isLoading = false

    this.$store.commit(VehicleMutationTypes.SET_FILTER, [
      { filterName: FilterNames.BRAND, item: brand },
      { filterName: FilterNames.MODEL, item: model },
      {
        filterName: FilterNames.FUEL,
        conditionOption: (item: any) => {
          return item.desc && item.desc.fuelType && item.desc.fuelType.toLowerCase()
        }
      },
      {
        filterName: FilterNames.ENGINE,
        conditionOption: (item: any) => {
          return item && item.desc && item.desc.enginesL
        }
      }
    ])
  }
})
